import React from 'react';
import './Start.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStackOverflow, faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import StepByStep from './StepByStep';


function Start() {

  const checked = true;


  return (
    <div>
      <div style={{ maxWidth: "500px", wordWrap: "break-word" }}>
        <StepByStep
          id="second"
          style={{ fontSize: "14px", lineHeight: "2" }}
          text="Welcome to my Website. Feel free to get in contact with me via one of the following channels:"
          interval={80}
        />
        <div>

        </div>
      </div>
      <Grid justify="center" className="socialIcons" container>

        <Zoom in={checked} style={{ transitionDelay: checked ? '500ms' : '0ms' }}>
          <a href="https://stackoverflow.com/users/8221303/hannes" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon className="Icon" icon={faStackOverflow} />
          </a>
        </Zoom>
        <Zoom in={checked} style={{ transitionDelay: checked ? '1000ms' : '0ms' }}>
          <a href="https://github.com/jreker" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon className="Icon" icon={faGithub} />
          </a>
        </Zoom>
        <Zoom in={checked} style={{ transitionDelay: checked ? '1500ms' : '0ms' }}>
          <a href=" https://twitter.com/jrekerde" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon className="Icon" icon={faTwitter} />
          </a>
        </Zoom>
      </Grid>



      { /*  <footer className="footer">
        <div>Imprint</div> 
        <div>| Privacy</div>
      </footer> */
      }
    </div>
  );
}

export default Start;
