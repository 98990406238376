import React from 'react';
import './App.css';
import Start from './Start';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import BrowserInfo from './BrowserInfo';
import "./fonts/PressStart2P-Regular.ttf"

function App() {

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="typewriter">
          </div>
          <h2>jreker.de</h2>


          <Switch>
            <Route path="/browserinfo">
              <BrowserInfo />
            </Route>
            <Route path="/">
              <Start />
            </Route>

          </Switch>

        </header>

        <footer className="Footer">
          <a href="/#/browserinfo"><div>Browserinfo</div> </a>
        </footer>

      </div>
    </Router>
  );
}

export default App;
