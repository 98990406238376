import React from 'react';
import './StepByStep.css';



function StepByStep(props) {
   
    let textLength = props.text.length;
    let counter = 0;
    let currentText = "";
    function test() {   
        if(counter < textLength) {
            currentText = currentText + props.text[counter];
            if(document.getElementById(props.id))
                document.getElementById(props.id).innerHTML = currentText;
            else 
                clearInterval();    
            counter++;
        } else {
            clearInterval();
        }
    }
    setInterval(() => test(), props.interval);
    
    return( 
        <div>
            <h6 id={props.id} style={props.style} className={props.cursor ? ["typewriter",props.className].join(' ') : ""}> </h6> 
        </div>
    )
}
export default StepByStep;