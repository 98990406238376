import React from 'react';
import Grid from '@material-ui/core/Grid';
import './BrowserInfo.css'

const BrowserInfo = props => {
    const gridLeft = 5;
    const gridRight = 7;
    //eslint-disable-next-line
    let latitude = "";
    //eslint-disable-next-line
    let longitude = "";

    console.log(navigator.javaEnabled());

    navigator.geolocation.getCurrentPosition((position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
    });
    
    let plugins = Array.from(navigator.plugins).map((x,y) => {return x.name + (y >= Array.from(navigator.plugins).length -1 ? "" : ", ")});


    return (
        <div>
            Browserinfo
            <br />
            <br />
            <Grid container style={{ maxWidth: "800px", fontSize: "0.8em", fontFamily: "monospace" }} >
                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.userAgent
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.userAgent ? navigator.userAgent : "not available"}
                </Grid>

                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.platform
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.platform ? navigator.platform : "not available"}
                </Grid>

                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.appVersion
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.appVersion ? navigator.appVersion : "not available"}
                </Grid>

                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.plugins
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.plugins ? 
                    plugins
                    : "not available"}
                </Grid>

                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.appName
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.appName ? navigator.appName : "not available"}
                </Grid>

             { /*   <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.geolocation .getCurrentPosition()
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.geolocation ? "longitude" + longitude + " / latitude " + latitude : "not available"}
                </Grid> */

            }

                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.language
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.language ? navigator.language : "not available"}
                </Grid>

                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.languages
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.languages ? Array.from(navigator.languages).map(x => x + " ") : "not available"}
                </Grid>

                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.vendor
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.vendor ? navigator.vendor : "not available"}
                </Grid>

                <Grid item className="gridLeft" xs={gridLeft}>
                    navigator.cookieEnabled
                </Grid>
                <Grid item className="gridRight" xs={gridRight}>
                    {navigator.cookieEnabled ? navigator.cookieEnabled ? "true" : "false" : "not available"}
                </Grid>


            </Grid>
        </div>

    )
}


export default BrowserInfo;
